$fallback-text-color: var(--fallback-text-color);
$primary-background-color: var(--primary-background-color);
$secondary-background-color: var(--secondary-background-color);
$welcome-title-color: var(--welcome-title-color);
$welcome-subtitle-color: var(--welcome-subtitle-color);
$login-form-bg-color: var(--login-form-bg-color);
$login-form-input-bg-color: var(--login-form-input-bg-color);
$login-form-input-text-color: var(--login-form-input-text-color);
$login-form-input-border-color: var(--login-form-input-border-color);
$login-form-btn-bg-color: var(--login-form-btn-bg-color);
$login-form-btn-text-color: var(--login-form-btn-text-color);
$login-form-links-color: var(--login-form-links-color);
$input-invalid-border-color: var(--input-invalid-border-color);
$form-error-color: var(--form-error-color);
$form-box-shadow-color: var(--form-box-shadow-color);
$header-menu-item-color: var(--header-menu-item-color);
$header-bg-color: var(--header-bg-color);
$header-selected-menu-item-color: var(--header-selected-menu-item-color);
$header-dropdown-bg-color: var(--header-dropdown-bg-color);
$header-dropdown-selected-bg-color: var(--header-dropdown-selected-bg-color);
$header-dropdown-item-text-color: var(--header-dropdown-item-text-color);
$header-dropdown-item-subtext-color: var(--header-dropdown-item-subtext-color);
$header-dropdown-selected-text-color: var(--header-dropdown-selected-text-color);
$header-dropdown-text-color: var(--header-dropdown-text-color);

$header-search-input-text-color: var(--header-search-input-text-color);
$footer-background-color: var(--footer-background-color);
$footer-color: var(--footer-color);
$landing-background-color: var(--landing-background-color);
$landing-color: var(--landing-color);
$landing-tertiary-color: var(--landing-tertiary-color);
$scrollTop-background-color: var(--scrollTop-background-color);
$scrollTop-color: var(--scrollTop-color);
$course-content-bg-color: var(--course-content-bg-color);
$all-courses-navbar-bg-color: var(--all-courses-navbar-bg-color);
$all-courses-navbar-title-color: var(--all-courses-navbar-title-color);
$all-courses-navbar-play-icon-color: var(--all-courses-navbar-play-icon-color);
$all-courses-grid-item-background-color: var(--all-courses-grid-item-background-color);
$all-courses-item-time-length-color: var(--all-courses-item-time-length-color);
$all-courses-item-subtitle-color: var(--all-courses-item-subtitle-color);
$all-courses-item-title-color: var(--all-courses-item-title-color);
$all-courses-item-no-course-color: var(--all-courses-item-no-course-color);
$all-courses-item-button-background-color: var(--all-courses-item-button-background-color);
$all-courses-item-button-primary-background-color: var(
  --all-courses-item-button-primary-background-color
);
$all-courses-item-button-color: var(--all-courses-item-button-color);
$all-courses-item-button-primary-color: var(--all-courses-item-button-primary-color);
$selected-menu-item-border-color: var(--selected-menu-item-border-color);
$selected-navbar-item-border-color: var(--selected-navbar-item-border-color);
$myCourses-navbar-bg-color: var(--myCourses-navbar-bg-color);
$myCourses-navbar-text-color: var(--myCourses-navbar-text-color);
$myCourses-navbar-selected-text-color: var(--myCourses-navbar-selected-text-color);
$myCourse-subjects-items-background-color: var(--myCourse-subjects-items-background-color);
$myCourse-subjects-items-active-bg-color: var(--myCourse-subjects-items-active-bg-color);
$myCourse-subjects-background-color: var(--myCourse-subjects-background-color);
$myCourse-subjects-color: var(--myCourse-subjects-color);
$myCourse-subjects-items-color: var(--myCourse-subjects-items-color);
$myCourse-subjects-finished-tick-color: var(--myCourse-subjects-finished-tick-color);
$myCourse-subjects-lang-toggle-background-color: var(
  --myCourse-subjects-lang-toggle-background-color
);
$myCourse-subjects-lang-toggle-text-color: var(--myCourse-subjects-lang-toggle-text-color);
$myCourse-subjects-scrollbar-color: var(--myCourse-subjects-scrollbar-color);
$myCourse-quizResult-bg-color: var(--myCourse-quizResult-bg-color);
$myCourse-quizResult-title-color: var(--myCourse-quizResult-title-color);
$myCourse-quizResult-text-primary-color: var(--myCourse-quizResult-text-primary-color);
$myCourse-quizResult-success-color: var(--myCourse-quizResult-success-color);
$myCourse-quizResult-failure-color: var(--myCourse-quizResult-failure-color);
$myCourse-quizResult-text-secondary-color: var(--myCourse-quizResult-text-secondary-color);
$myCourse-quizResult-btn-bg-color: var(--myCourse-quizResult-btn-bg-color);
$myCourse-quizResult-btn-text-color: var(--myCourse-quizResult-btn-text-color);
$myCourse-details-title-color: var(--myCourse-details-title-color);
$myCourse-details-text-color: var(--myCourse-details-text-color);
$myCourse-play-btn-bg-color: var(--myCourse-play-btn-bg-color);
$myCourse-play-btn-text-color: var(--myCourse-play-btn-text-color);
$myCourse-play-again-btn-text-color: var(--myCourse-play-again-btn-text-color);
$myCourse-completed-text-color: var(--myCourse-completed-text-color);
$faq-background-color: var(--faq-background-color);
$faq-button-color: var(--faq-button-color);
$faq-question-color: var(--faq-question-color);
$faq-answer-color: var(--faq-answer-color);
$trainer-container-color: var(--trainer-container-color);
$trainer-container-background-color: var(--trainer-container-background-color);
$add-discussion-button-background-color: var(--add-discussion-button-background-color);
$add-discussion-button-color: var(--add-discussion-button-color);
$certificate-button-background-color: var(--certificate-button-background-color);
$certificate-button-color: var(--certificate-button-color);
$no-course-text-color: var(--no-course-text-color);
$no-course-btn-bg-color: var(--no-course-btn-bg-color);
$no-course-btn-text-color: var(--no-course-btn-text-color);
$progress-empty-color: var(--progress-empty-color);
$progress-fill-color: var(--progress-fill-color);
$certificate-download-icon-color: var(--certificate-download-icon-color);
$profile-primary-text-color: var(--profile-primary-text-color);
$profile-form-bg-color: var(--profile-form-bg-color);
$profile-form-btn-bg-color: var(--profile-form-btn-bg-color);
$profile-form-btn-text-color: var(--profile-form-btn-text-color);
$profile-form-input-text-color: var(--profile-form-input-text-color);
$profile-form-input-bg-color: var(--profile-form-input-bg-color);
$profile-form-input-border-color: var(--profile-form-input-border-color);
$career-title-primary-color: var(--career-title-primary-color);
$career-title-secondary-color: var(--career-title-secondary-color);
$career-title-tertiary-color: var(--career-title-tertiary-color);
$career-btn-primary-bg-color: var(--career-btn-primary-bg-color);
$career-btn-primary-text-color: var(--career-btn-primary-text-color);
$career-posProgress-bg-color: var(--career-posProgress-bg-color);
$career-actualPos-color: var(--career-actualpos-color);
$career-level1Pos-color: var(--career-level1pos-color);
$career-level2Pos-color: var(--career-level2pos-color);
$career-posDetails-text-color: var(--career-posDetails-text-color);
$career-selector-bg-color: var(--career-selector-bg-color);
$career-selector-text-color: var(--career-selector-text-color);
$career-graph-actual-bg-color: var(--career-graph-actual-bg-color);
$career-graph-required-bg-color: var(--career-graph-required-bg-color);
$devplan-primary-text-color: var(--devplan-primary-text-color);
$devplan-table-border-color: var(--devplan-table-border-color);
$devplan-btn-primary-text-color: var(--devplan-btn-primary-text-color);

$errorpage-icon-color: var(--errorpage-icon-color);
$errorpage-title-color: var(--errorpage-title-color);
$errorpage-text-color: var(--errorpage-text-color);
$errorpage-btn-bg-color: var(--errorpage-btn-bg-color);
$errorpage-btn-text-color: var(--errorpage-btn-text-color);
$error-404-page-bg-color: var(--error-404-page-bg-color);
$error-500-page-bg-color: var(--error-500-page-bg-color);
$landing-bg-desktop: var(--landing-bg-desktop);
$landing-bg-mobile: var(--landing-bg-mobile);
$landing-bg-tablet: var(--landing-bg-tablet);

$login-bg-desktop: var(--login-bg-desktop);
$login-bg-mobile: var(--login-bg-mobile);
$login-bg-tablet: var(--login-bg-tablet);

$font-family: var(--font-family);
$heading-font-family: var(--heading-font-family);
$border-radius: var(--border-radius);
// @font-face {
//     font-family: "Avenir Next LT Pro";
//     src: url("./fonts/Avenir_Next_LT_Pro_Mid.ttf");
//     font-weight: 400;
// }

// @font-face {
//     font-family: "Avenir Next LT Pro";
//     src: url("./fonts/Avenir_Next_LT_Pro_Bold.ttf");
//     font-weight: bold;
// }

// @font-face {
//     font-family: "Droid Arabic Kufi";
//     src: url("./fonts/DroidKufi-Regular.ttf");
//     font-weight: 400;
// }

// @font-face {
//     font-family: "Droid Arabic Kufi";
//     src: url("./fonts/DroidKufi-Bold.ttf");
//     font-weight: bold;
// }

.fullWidthChild {
  width: 100vw !important;
  max-width: 100vw !important;
  position: relative !important;
  left: calc(-50vw + 50%) !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
body {
  background-color: $primary-background-color !important;
  color: $fallback-text-color;
  font-family: $font-family;
  overflow-x: hidden;

  .active-section {
    background-color: $myCourse-subjects-items-active-bg-color !important;
  }

  .iframe-active-video,
  .iframe-active-iframe {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .iframe-active-video::before,
  .iframe-active-iframe:before {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  .iframe-active-video iframe,
  .iframe-active-iframe iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .login-page {
    @extend .fullWidthChild;
    background-color: $secondary-background-color;
    background-size: cover;
    background-position: center;

    @media (min-width: 1025px) {
      background-image: $login-bg-desktop;
    }
    @media (min-width: 577px) and (max-width: 1024px) {
      background-image: $login-bg-tablet;
    }
    @media (max-width: 576px) {
      background-image: $login-bg-mobile;
    }
  }

  // .login-wrapper {
  //     padding: 30px 0;
  // }

  .login-image {
    > div {
      position: relative;
    }
    .background {
      position: absolute;
      top: -45%;
      opacity: 0.6;
    }
  }

  .flex-1 {
    flex: 1 !important;
  }

  .login-box {
    background-color: $login-form-bg-color;
    max-width: 543px;
    padding: 30px;
    padding-bottom: 40px;
    border-radius: 8px;
    margin-left: auto;
    box-shadow: 0px 3px 8px $form-box-shadow-color;
  }

  .login-footer {
    max-width: 543px;
    margin-left: auto;
    padding: 30px;
    color: $login-form-links-color;

    .left {
      a {
        color: $login-form-links-color;
      }
    }
  }

  .login-field {
    position: relative;
    svg {
      position: absolute;
      right: 23.59px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      cursor: pointer;
    }
  }

  .login-field.is-valid {
    svg {
      right: 50px;
    }
  }

  .login-field.is-invalid {
    svg {
      right: 23.59px;
    }
  }

  @media (max-width: 1199px) {
    .login-box {
      margin: 20px auto 0;
    }
    .account-box {
      margin: auto;
      width: auto !important;
      padding-left: 20px !important;
    }
    .profile-box {
      margin: auto;
      width: auto !important;
    }
    .login-footer {
      margin: auto;
    }

    .login-image {
      justify-content: center;
    }
  }

  @media (max-width: 594px) {
    .login-image {
      justify-content: flex-start !important;
    }
  }

  @media (max-width: 612px) {
    .login-box {
      width: 100%;
    }
    .login-footer {
      width: 100%;
    }
  }

  .login-message {
    color: $welcome-title-color;
    font-size: 40px;
  }
  // .login-wel-message{
  //     color:;
  //     font-size: 24px;
  // }
  .login-gray-message {
    color: $welcome-subtitle-color;
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: 490px) and (max-width: 594px) {
    .login-message {
      font-size: 28px;
    }
    .login-wel-message {
      font-size: 18px;
    }
    .login-gray-message {
      font-size: 18px;
    }
  }

  .landing {
    @extend .fullWidthChild;
    background-color: $landing-background-color;
    color: $landing-color;
    background-size: cover;
    background-position: center;

    @media (min-width: 1025px) {
      background-image: $landing-bg-desktop;
    }
    @media (min-width: 577px) and (max-width: 1024px) {
      background-image: $landing-bg-tablet;
    }
    @media (max-width: 576px) {
      background-image: $landing-bg-mobile;
    }

    padding: 100px 0;
    h1 {
      font-size: 58px;
      color: $landing-color;
      line-height: 1.3;

      span.wel-text {
        // margin-right: 18px;
        align-self: flex-end;
      }

      img.logo {
        margin-right: 14px;
      }
    }
    p {
      color: $landing-tertiary-color;
      font-size: 24px;
    }

    .illusRightContainer {
      background-color: $landing-background-color;
    }

    img#illusRight {
      max-width: 100%;
      object-fit: contain;
      max-height: 400px;
    }

    .marketIcons {
      margin-top: 60px;

      .marketIcon:first-child {
        margin-right: 12px;
      }
      .marketIcon {
        svg {
          height: 65px;
          width: 213px;
          max-height: 65px !important;
          border: 3px solid $landing-color;
        }
        text:first-child {
          font-size: 10px !important;
        }
        text:last-child {
          font-size: 18px !important;
        }
      }
    }
    @media (min-width: 576px) and (max-width: 992px) {
      padding: 75px 25px !important;
    }
  }

  .landing.noCourse {
    height: calc(100vh - 125px);
    background-color: $primary-background-color;
    padding: 0;
    display: flex;
    align-items: center;

    @media (min-width: 1025px) {
      background-image: $login-bg-desktop;
    }
    @media (min-width: 577px) and (max-width: 1024px) {
      background-image: $login-bg-tablet;
    }
    @media (max-width: 576px) {
      background-image: $login-bg-mobile;
    }

    h1 {
      font-size: 28px;
      color: $all-courses-item-title-color;
    }
    p {
      font-size: 16px;
      color: $all-courses-item-no-course-color;
      margin: 20px 0 0 0;
    }
    button {
      background: $no-course-btn-bg-color;
      color: $no-course-btn-text-color;
      font-size: 24px;
      padding: 10px 40px;
      border: 0;
      margin: 25px 0 0 0;
    }

    // img#illusRight {
    //     -webkit-filter: grayscale(100%);
    //     filter: grayscale(100%);
    //     // mix-blend-mode: luminosity;
    // }
  }

  .allCourses {
    // margin-top: 20px;

    .navbarMyCourses {
      border-top: 0;
    }

    // .allCoursesTitle {
    //     font-size: 32px;
    //     color: inherit;
    //     font-weight: bold;
    // }

    // .allCoursesIcons {
    //     display: flex;
    //     align-items: center;

    //     .allCoursesIcons_inner {
    //         margin-left: 0;
    //     }

    //     span {
    //         margin-right: 10px;
    //     }

    //     select {
    //         margin-left: 10px;
    //         padding: 0 5px;
    //         background: inherit;
    //         color: inherit;
    //         border: 0;
    //     }
    // }

    // .allCoursesIcons.myCourses {
    //     margin-top: 20px;
    // }

    .grid path {
      fill: rgba(0, 0, 0, 0) !important;
    }

    .play,
    .play-again {
      margin-right: 40px;
      border-radius: $border-radius;
      svg {
        width: 21px !important;
        height: 27px !important;
        path {
          fill: $all-courses-navbar-play-icon-color !important;
        }
      }
    }

    .section {
      @extend .fullWidthChild;
      height: 66px;
      background-color: $all-courses-navbar-bg-color;
      color: $all-courses-navbar-title-color;
      display: flex;
      align-items: center;
      // margin-top: 30px;
      // position: absolute;
      // left: 0;
      .title {
        font-size: 24px;
        padding-left: 5px;
      }
    }

    .allCoursesNoCourse {
      @extend .fullWidthChild;
      background-color: $secondary-background-color;
      .noCourse {
        margin: 50px 0;
        color: $no-course-text-color;
        h1 {
          color: $welcome-title-color;
          margin-bottom: 25px;
        }
      }
    }

    .allCoursesGrid.noCourse {
      @extend .fullWidthChild;
      margin: 0 !important;
      grid-template-columns: 1fr !important;
    }

    .allCoursesGrid {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 32px 20px;
      padding: 0;

      .allCoursesGridItem {
        background-color: $all-courses-grid-item-background-color;
        padding: 15px;
        box-shadow: 0px 3px 8px $form-box-shadow-color;
        .allCoursesGridItemImage {
          position: relative;
          width: 100%;
          height: 158px;
          background: rgba(0, 0, 0, 0.1);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;

          .allCoursesGridItemType {
            position: absolute;
            top: 0;
            padding: 5px;
            color: $all-courses-item-title-color;
            background-color: $all-courses-grid-item-background-color;

            &.start {
              left: 0;
              border-bottom-right-radius: 4px;
              span{
                margin-left: 4px;
              }
            }
            &.end {
              right: 0;
              border-bottom-left-radius: 4px;
              span{
                margin-right: 4px;
              }
            }
            svg{
              path {
                fill: $all-courses-item-title-color;
              }
            }
          }
        }

        path {
          fill: rgb(0, 0, 0, 0);
        }

        .allCoursesGridInfo {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          color: $all-courses-item-time-length-color;
          font-size: 14px;

          svg {
            margin-right: 5px;
            path {
              stroke: $all-courses-item-time-length-color !important;
            }
          }

          // &_Time, &_Length {
          //     span {
          //         font-weight: bold;
          //     }
          // }
        }

        .allCoursesGridItemSubtitle {
          color: $all-courses-item-subtitle-color;
          font-size: 12px;
          margin-top: 18px;
          min-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .allCoursesGridItemTitle {
          color: $all-courses-item-title-color;
          font-weight: bold;
          font-size: 18px;
          margin-top: 4px;
          height: 60px;
          max-height: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .line-progress-bar-container {
          width: 100%;
          height: 10px;
          background-color: $primary-background-color;
          border-radius: 4px;
          overflow: hidden;
          .line-progress-bar {
            height: 10px;
            background-color: $myCourses-navbar-bg-color;
            border-radius: 4px;
          }
        }
        .line-progress-completed {
          min-width: 100px;
          font-size: 12px;
          margin: 0;
          color: $myCourse-completed-text-color;
        }

        button.allCoursesGridItemButton {
          border-radius: 2px;
          width: 100%;
          height: 36px;
          margin-top: 16px;
          border: 0;
          font-size: 14px;
          line-height: 36px;
          background-color: $all-courses-item-button-background-color;
          color: $all-courses-item-button-color;
          border-radius: $border-radius;
        }

        button.primary {
          background-color: $all-courses-item-button-primary-background-color !important;
          color: $all-courses-item-button-primary-color !important;
        }

        button.primary.completed {
          background-color: $all-courses-item-button-background-color !important;
          color: $all-courses-item-button-color !important;
        }
      }
    }

    .trainingKPI {
      display: flex;
      flex-direction: column;
      padding: 18px;
      margin: 32px 20px 0 20px;
      background-color: $all-courses-grid-item-background-color;
      color: $all-courses-item-subtitle-color;
      width: fit-content;
      border-radius: 2px;
      .title {
        font-size: 18px;
        color: $all-courses-item-title-color;
      }
      .values {
        font-size: 24px;
        font-weight: bold;
      }
      .labels {
        font-size: 12px;
      }
    }

    @media (min-width: 801px) and (max-width: 1200px) {
      .allCoursesGrid {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    @media (min-width: 501px) and (max-width: 800px) {
      .allCoursesGrid {
        grid-template-columns: 1fr 1fr;
      }
    }

    @media (max-width: 500px) {
      .allCoursesGrid {
        grid-template-columns: 1fr;
        margin: 32px 0;
        padding: 0 8px;
      }
    }

    @media (min-width: 993px) {
      //large, x-large
      .allCoursesIcons_inner {
        margin-left: auto !important;
      }
    }
  }

  .drawer-overlay {
    z-index: 0 !important;
  }

  @media (min-width: 992px) {
    span::-webkit-scrollbar,
    ol::-webkit-scrollbar,
    ul::-webkit-scrollbar,
    pre::-webkit-scrollbar,
    div:not(.jobSelect)::-webkit-scrollbar {
      width: 15px !important;
    }

    span::-webkit-scrollbar-track,
    ol::-webkit-scrollbar-track,
    ul::-webkit-scrollbar-track,
    pre::-webkit-scrollbar-track,
    div:not(.jobSelect)::-webkit-scrollbar-track {
      background-color: $secondary-background-color !important;
    }

    span::-webkit-scrollbar-thumb,
    ol::-webkit-scrollbar-thumb,
    ul::-webkit-scrollbar-thumb,
    pre::-webkit-scrollbar-thumb,
    div:not(.jobSelect)::-webkit-scrollbar-thumb {
      background-color: $myCourse-subjects-scrollbar-color !important;
      border-left: 4px solid $secondary-background-color !important;
      border-right: 4px solid $secondary-background-color !important;
      // border-radius: 5px !important;
    }
  }
}

.footer {
  background-color: $footer-background-color;
  color: $footer-color;
  font-size: 12px;
  span {
    color: $footer-color !important;
  }
}

.scrolltop {
  background-color: $scrollTop-background-color;
  color: $scrollTop-color;
}

#kt_header {
  background-color: $header-bg-color !important;
}
.header-fixed[data-kt-sticky-header='on'] .header {
  height: 80px !important;
}

.header-menu.drawer {
  background-color: $secondary-background-color;
}

.ml-auto {
  margin-left: auto;
}

.menu-item.headerMenuItem {
  // width: 170px;
  margin: 0 !important;
  margin-left: 30px !important;
  border-radius: 0;
  .menu-title {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: $header-menu-item-color !important;
  }
}

.menu-item.selectedItem {
  .menu-title {
    color: $header-selected-menu-item-color !important;
  }
}

.selectedWithBorder {
  margin-top: 7px;
  font-weight: 700 !important;
  color: $myCourses-navbar-selected-text-color !important;
}

.selectedWithBorder::after {
  content: '';
  display: block;
  position: relative;
  top: 0;
  border-top: 7px solid $selected-menu-item-border-color;
  border-radius: $border-radius;
}

.menu-item.headerMenuItem .menu-link:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.toolbarBell path {
  fill-opacity: 0 !important;
  stroke: #b5bec3;
}

.toolbarGrid path {
  fill: #b5bec3 !important;
}

.toolbarName {
  color: $header-menu-item-color !important;
  font-size: 18px !important;
}

.toolbarArrow {
  svg {
    width: 18px !important;
    height: 10px !important;
    margin: 0 12px;
    path {
      fill: $header-menu-item-color !important;
    }
  }
}

.error-icon {
  font-size: 4rem;
  line-height: 2;
}

.errorBox,
.indicatorBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 125px);
  flex-direction: column;
  font-weight: 400 !important;
}

.pageContainer {
  padding: 0px 0px 20px 0px;
  .courseContent {
    @extend .fullWidthChild;
    background-color: $primary-background-color;
  }
  .coursePreview {
    width: 100%;
    display: flex;
    //padding-top: 32px;
    //padding-bottom: 4px;

    .accordion {
      button.withArrow::before {
        margin-top: -5px;
        flex-shrink: 0;
        width: 20px;
        height: 11px;
        margin-left: 0;
        margin-right: 10px;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.828' height='11.414' viewBox='0 0 20.828 11.414'%3E%3Cpath id='Icon_feather-chevron-right' data-name='Icon feather-chevron-right' d='M13.5,27l9-9-9-9' transform='translate(28.414 -12.086) rotate(90)' fill='none' stroke='%23fafafa' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
        transition: transform 0.2s ease-in-out;
        transform: rotate(-90deg);
      }

      .active-unitindex::before {
        transform: rotate(0deg) !important;
      }
    }

    .introAccordion {
      svg {
        //margin-right:10px;
        path {
          fill: white !important;
        }
      }
    }

    .subjectsAccordion {
      button {
        text-align: left;
        background: transparent;
        color: white;
        border: 0;
        padding: 14px 0;
        margin: 0;
        border-radius: 2px;

        .unit-text {
          padding: 0 12px;
        }
        svg {
          path {
            fill: white !important;
          }
        }
        .left-icon svg {
          path {
            stroke: $myCourse-subjects-finished-tick-color;
          }
        }
        .tickIcon {
          svg {
            width: 18.055px !important;
            height: 12.882px !important;
            margin-top: -5px;
            path {
              fill: transparent !important;
            }
          }
        }

        .play-unit {
          position: absolute;
          right: 0%;
          svg {
            margin-right: 0 !important;
            margin-top: 4px;
            width: 18px !important;
            height: 18px !important;
          }
        }

        span.left-icon {
          min-width: 18.05px !important;
        }
      }
    }

    .collapse:last-child button {
      padding-bottom: 24px !important;
    }

    .coursePreview_Actions {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: rgba(0, 0, 0, 0.1);
      background-size: cover;
      background-position: center;

      .coursePreview_ActionsCover {
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          10deg,
          rgba(29, 37, 45, 1) 0%,
          rgba(55, 62, 69, 0.69) 10%,
          rgba(57, 71, 85, 0.24) 100%
        );
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
      }

      .coursePreview_ActionsInfo {
        position: relative;
        padding: 0 30px 30px 30px;
        h1 {
          color: #f4f4f4;
          font-size: 36px;
        }
        .coursePreview_ActionsButtons {
          display: flex;
          margin-top: 25px;
          align-items: center;
          button {
            height: 62px;
            line-height: 62px;
            padding: 0 50px;
            border-radius: $border-radius;
            border: 0;
            font-size: 18px;
          }
          .info_container{
            display: flex;
          .info {
            margin-left: 1rem;
            h4 {
              color: $primary-background-color;
              padding-left: 0.5rem;
              margin: 0;
            }
            i {
              color: $primary-background-color;
            }
            svg {
              path {
                stroke: $primary-background-color;
              }
            }
          }
        }
          button.play {
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            font-weight: bold;
            background-color: $myCourse-play-btn-bg-color !important;
            color: $myCourse-play-btn-text-color !important;
            svg {
              width: 35px !important;
              height: 35px !important;
              path {
                fill: $myCourse-play-btn-text-color !important;
              }
            }

            span {
              line-height: normal;
              margin-right: 10px;
            }
          }

          button.play-again {
            font-size: 18px;
            line-height: 24px;
            background: none;
            display: flex;
            align-items: center;
            font-weight: bold;
            background-color: transparent;
            color: $myCourse-play-again-btn-text-color !important;
            svg {
              width: 35px !important;
              height: 35px !important;
              path {
                fill: $myCourse-play-again-btn-text-color !important;
              }
            }

            span {
              line-height: normal;
              margin-right: 10px;
            }
          }

          button.addFavorite {
            padding: 0 25px;
            path {
              fill: rgba(0, 0, 0, 0) !important;
              stroke: white !important;
            }
          }
          button.join{
            font-size: 18px;
            line-height: 24px;
            background-color: $myCourse-play-btn-bg-color !important;
            color: $myCourse-play-btn-text-color !important;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
    }

    .coursePreview_Subjects {
      min-width: 380px;
      max-width: 380px;
      background-color: $myCourse-subjects-background-color;
      color: $myCourse-subjects-color;
      //height: 551px;
      overflow-y: auto;
      position: relative;

      .title {
        font-size: 24px;
        font-weight: bold;
        padding: 30px;
        // background: #394755;
      }

      .coursePreview_Items {
        position: absolute;
        width: 100%;
      }

      .coursePreview_Items .card {
        background-color: $myCourse-subjects-items-background-color;
        margin: 0;
        border-radius: 0;

        .card-header {
          border-bottom: 3px solid $myCourse-subjects-background-color;
        }

        button {
          position: relative;
          font-size: 16px !important;
          line-height: 22px;
          display: flex;
          align-items: flex-start;
          color: $myCourse-subjects-items-color !important;
          text-align: inherit;
        }
      }
    }

    .quizResultsContainer {
      display: flex;
      align-items: center;
      // width: 100%;
      height: 100%;
      padding: 24px 0;
      background: var(--myCourse-quizResult-bg-color);
      color: var(--primary-text-color);
    }

    .quizResultsTitle {
      font-size: 36px;
      font-weight: 600;
      color: $myCourse-quizResult-title-color;
    }

    .quizResultsSubTitle {
      font-size: 16px;
      font-weight: 600;
      color: $myCourse-quizResult-text-primary-color;
    }

    .quizResultsSuccessText {
      font-size: 28px;
      font-weight: 600;
      color: $myCourse-quizResult-success-color;
    }

    .quizResultsFailureText {
      font-size: 28px;
      font-weight: 600;
      color: $myCourse-quizResult-failure-color;
    }

    .quizResultsSubText {
      font-size: 16px;
      color: $myCourse-quizResult-text-secondary-color;
    }

    .quizResultsButton {
      background-color: $myCourse-quizResult-btn-bg-color !important;
      color: $myCourse-quizResult-btn-text-color !important;
      width: 200px;
      border: 0;
      padding: 12px;
      font-size: 18px;
      border-radius: 8px;
    }
  }

  .myCourse_overview {
    h2 {
      color: $myCourse-details-title-color;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 32px;
    }

    h2.long::after {
      content: '';
      display: block;
      position: relative;
      top: 0;
      width: 80px;
      border-top: 7px solid $selected-menu-item-border-color;
    }

    h2.short::after {
      content: '';
      display: block;
      position: relative;
      top: 0;
      width: 50px;
      border-top: 7px solid $selected-menu-item-border-color;
    }

    p {
      color: $myCourse-details-text-color;
      font-size: 18px;
      line-height: 24px;
      margin-top: 32px;
    }

    .myCourse_courseOverview,
    .myCourse_whoShouldAttend {
      color: $myCourse-details-text-color;
      font-size: 18px;
      line-height: 24px;
      margin-top: 67px;

      ul {
        margin: 0;
        list-style-type: none;
        font-size: 20px;
        color: $myCourse-details-text-color;
      }
      ul > li {
        text-indent: -5px;
      }
      ul > li:before {
        content: '-';
        text-indent: -5px;
        font-size: 60px;
        line-height: 48px;
        font-weight: bold;
        margin-right: 15px;
      }
    }

    .columnRight {
      img {
        margin: 56px 0px;
        max-width: 100%;
        width: 100%;
        mix-blend-mode: luminosity;
      }

      .myCourse_FAQ {
        .myCourse_FAQItems {
          margin-top: 36px;
          .card {
            background-color: $faq-background-color !important;
            color: $faq-answer-color !important;
            margin-bottom: 20px;
            .card-header {
              border: 0;
            }
            button {
              color: $faq-question-color !important;
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              align-items: center;
              div.withEllipsis {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
            .card-body {
              font-size: 14px;
              line-height: 19px;
              padding: 14px 30px 18px;
            }
          }
        }
      }
    }

    .trainerContainer {
      background: $trainer-container-background-color;
      border-radius: 2px;
      padding: 30px;
      margin-top: 50px;
      .trainerName {
        font-size: 24px;
      }
      p {
        margin: 0 40px;
        font-size: 20px;
        line-height: 32px;
        color: $trainer-container-color !important;
      }
      img {
        min-width: 0;
      }
    }
  }

  .discussionTab {
    margin: 56px 0;

    .noDissussionMessage {
      color: $welcome-title-color;
      font-size: 24px;
    }

    button.addDiscussionButton {
      background-color: $add-discussion-button-background-color;
      color: $add-discussion-button-color;
      font-size: 14px;
      border: 0;
      width: 210px;
      height: 36px;
      line-height: 36px;
      margin-top: 40px;
    }
  }

  .certificatesTab {
    margin: 10px 0px;

    .certificate {
      padding: 0;

      button.certificateButton {
        width: 100%;
        background-color: $certificate-button-background-color;
        box-shadow: 0px 3px 8px $form-box-shadow-color;
        border-radius: 2px;
        color: $certificate-button-color;
        font-size: 16px;
        line-height: 32px;
        height: 66px;
        border: 0;
        margin: 10px 0px;
        padding: 0 50px;

        svg {
          width: 27px;
          height: 27px;
          path {
            fill: rgba(0, 0, 0, 0);
            stroke: $certificate-download-icon-color;
          }
        }
      }
    }
  }

  .certificate:nth-child(2n-1) {
    padding-right: 10px;
  }

  .certificate:nth-child(2n) {
    padding-left: 10px;
  }
}

.dropdownHeader {
  border-radius: 0px;
  padding-bottom: 0px !important;
  border-radius: $border-radius;
}
.dropdownHeader .menu-item {
  padding: 0px 6px 7px 6px !important;
}
.dropdownHeader a.menu-link {
  font-size: 18px;
  color: $header-dropdown-text-color !important;
  display: block !important;
}
.dropdownHeader a.menu-link:hover {
  background-color: $header-dropdown-selected-bg-color !important;
  color: $header-dropdown-selected-text-color !important;
  border-radius: $border-radius;
  .span-second {
    color: $header-dropdown-selected-text-color !important;
  }
}

.span-first {
  display: block;
}
.span-second {
  display: block;
  font-size: 12px;
  color: $header-dropdown-item-subtext-color !important;
}

.title-login-form {
  color: $login-form-btn-bg-color;
  font-size: 32px;
  font-weight: bold;
  &.profile {
    color: $login-form-btn-bg-color;
  }
}

.account-box .title-login-form {
  font-size: 28px;
}

#input-login {
  background-color: $login-form-input-bg-color !important;
  border-radius: $border-radius;
  color: $login-form-input-text-color !important;
  border: 1px solid $login-form-input-border-color;
  font-weight: 400;
  &.profile {
    color: $profile-form-input-text-color !important;
    background-color: $profile-form-input-bg-color !important;
    border-color: $login-form-input-border-color !important;
  }
}
#input-login::placeholder {
  color: $login-form-input-text-color;
}

.form-control.is-invalid {
  border: 2px solid $input-invalid-border-color !important;
  background-image: none !important;
}

#kt_sign_in_submit {
  //width: 100% !important;
  border-radius: 0px;
  font-weight: 500 !important;
  font-size: 18px !important;
  background-color: $login-form-btn-bg-color !important;
  color: $login-form-input-bg-color!important;
  border-radius: $border-radius;
  &.profile {
    background-color: $profile-form-btn-bg-color !important;
    color: $profile-form-btn-text-color !important;
  }
}

.forgot-password-text {
  color: $login-form-links-color !important;
  font-size: 18px !important;
  align-items: center !important;
  margin-top: 0.75rem;
}

.error-alert {
  color: $form-error-color;
  font-size: 14px;
  font-weight: bold;
}
.navbarMyCourses {
  @extend .fullWidthChild;
  background-color: $myCourses-navbar-bg-color;
  color: $myCourses-navbar-text-color;
  height: 66px;
  display: flex;
  align-items: center;
  .container {
    .navbarMyCourses_Item {
      padding: 0 15px;
      cursor: pointer;
      font-size: 18px;
      span {
        padding: 0 15px;
        opacity: 0.65;
      }
    }
  }
}

.navbarMyCourses_Item.selectedWithBorder {
  span {
    opacity: 1 !important;
  }
}

.navbarMyCourses_Item.selectedWithBorder::after {
  border-color: $selected-navbar-item-border-color !important;
}

.progressC {
  width: 65px;
  height: 65px;
  line-height: 65px;
  background: none;
  box-shadow: none;
  position: relative;
}

.progressC:after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid $progress-empty-color;
  position: absolute;
  top: 0;
  left: 0;
}

.progressC > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progressC .progress-left {
  left: 0;
}

.progressC .progressBar {
  width: 100%;
  height: 100%;
  background: none;
  border: 5px solid $progress-fill-color;
  position: absolute;
  top: 0;
}

.progressC .progress-left .progressBar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
  //transform: rotate(180deg);
}

.progressC .progress-right {
  right: 0;
}

.progressC .progress-right .progressBar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  //transform: rotate(180deg);
}

.progressC .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: none;
  font-size: 12px;
  color: $progress-fill-color;
  line-height: 60px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}

.progressC.blue .progressBar {
  border-color: #fff;
}

.progress-completed {
  margin: 5px 0px;
  font-size: 12px;
  color: $progress-fill-color;
}

.courseProgress {
  margin-top: 20px;
}

// .progress {
//     background-color: #4c5b69;
//     border-radius: 0;
//     height: 10px;

//     .progress-bar {
//         background-color: #ffffff;
//     }
// }

.blurContent {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.mobile-menu {
  display: none;
  position: fixed;
  z-index: 2;
  flex-wrap: wrap;
  gap: 8px;
  padding: 6px;
  bottom: 0px;
  width: 100%;
  background: $secondary-background-color;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  // height: 92px;
  a {
    color: $header-menu-item-color !important;
  }

  a.selectedWithBorder {
    color: $header-selected-menu-item-color !important;
  }
}

#userDropdownMobile {
  display: none;
  cursor: pointer;
}

.mobileMenuContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 65px);
  background: $primary-background-color;
  z-index: 99;
  align-items: center;
  opacity: 0;
  animation: fadeIn 0.5s ease-in both;
  div {
    font-size: 24px;
    font-weight: 500;
    padding: 0 0 24px;
    span {
      font-size: 12px;
      opacity: 0.9;
      font-weight: 400;
    }
  }

  .welcome-text {
    padding: 74px 0 48px;
    color: $welcome-title-color;
    font-weight: bold;
  }

  a {
    color: $header-dropdown-item-text-color;
    div {
      font-size: 20px;
    }
    .details {
      color: $header-dropdown-item-subtext-color;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate3d(0, -20%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.title-account {
  margin-top: 7px;
  width: 79px !important;
}

.title-account::after {
  content: '';
  display: block;
  position: relative;
  top: 0;
  width: 79px !important;
  border-top: 7px solid $selected-menu-item-border-color;
}

.account-box {
  background-color: $login-form-bg-color;
  width: 543px;
  padding: 30px;
  padding-bottom: 40px;
  border-radius: 2px;
  margin-left: auto;
  margin-left: 5rem;
  min-height: 379px;
  height: auto;
  border-radius: $border-radius;
  box-shadow: 0px 3px 8px $form-box-shadow-color;
  &.profile {
    background-color: $profile-form-bg-color;
  }
}

.menu-sub {
  background-color: $header-dropdown-bg-color;
  color: $header-menu-item-color;
}

.searchBox {
  .searchInput,
  .searchInput:focus,
  a {
    color: $header-search-input-text-color !important;
  }
}

#alert {
  color: #f5f5f5 !important;
}
.alert-badge {
  background-color: #394755 !important;
  color: #b5bec3 !important;
}

.tickIcon {
  polygon {
    fill: #00c48c;
  }
}

.header {
  border: 0 !important;
}
.div-faqPlus {
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-left: 15px;

  svg path {
    stroke: $faq-button-color !important;
  }
}
.svg-icon svg {
  width: 21px;
  height: 21px;
}

.sortSelect {
  padding-right: 40px;
  color: $all-courses-navbar-title-color;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
  overflow: hidden;
  //background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.035' height='10.231' viewBox='0 0 18.035 10.231' class='mh-50px'%3E%3Cpath id='Icon_awesome-caret-down' data-name='Icon awesome-caret-down' d='M2.008,13.5H17.616a1.211,1.211,0,0,1,.855,2.069l-7.8,7.807a1.216,1.216,0,0,1-1.717,0l-7.8-7.807A1.211,1.211,0,0,1,2.008,13.5Z' transform='translate(-0.794 -13.5)' fill='#000000' %3E%3C/path%3E%3C/svg%3E") no-repeat right;
  //-webkit-appearance: none;
  //background-position: right 5px center;
  background: var(--all-courses-navbar-bg-color);

  option {
    background: $all-courses-navbar-title-color;
    color: $all-courses-navbar-bg-color;
  }
}

.sortSelectMobile {
  color: $all-courses-navbar-bg-color;
  border: 0;
  outline: 0;
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  font-size: 16px;
  width: auto;
  background: none;
  option {
    background: $all-courses-navbar-title-color;
    color: $all-courses-navbar-bg-color;
  }
}

.profile-title {
  font-weight: bold;
  color: $login-form-btn-bg-color !important;
  font-size: 28px;
}

.changeLanguage {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: $header-menu-item-color !important;
  margin-left: 10px;
  cursor: pointer;
}

.changeLanguageAuth {
  cursor: pointer;
}

// .pageContainer .myCourse_overview .myCourse_courseOverview ul > li:before {
//     color: $primary-button-background-color ;

// }
.profile-box {
  p {
    font-size: 16px;
    font-weight: 400;
    color: $profile-primary-text-color;
  }

  .user-name-pro {
    font-size: 18px;
    font-weight: 500;
    color: $login-form-btn-bg-color;
  }
}

.search-icon-header {
  svg {
    path {
      fill: $header-menu-item-color !important;
    }

    rect {
      fill: $header-menu-item-color !important;
      opacity: 1 !important;
    }
  }
}
.searchInput::placeholder,
.tm-color {
  color: $header-search-input-text-color !important;
}
.searchBoxSVG,
.not-found {
  svg {
    path {
      fill: $header-search-input-text-color !important;
    }

    rect {
      fill: $header-search-input-text-color !important;
      opacity: 1 !important;
    }
  }
}

.errorPage {
  &#error404{
    background-color: $error-404-page-bg-color;
  }
  &#error500{
    background-color: $error-500-page-bg-color;
  }
  background-size: cover;
  background-position: center;

  @media (min-width: 1025px) {
    background-image: $login-bg-desktop;
  }
  @media (min-width: 577px) and (max-width: 1024px) {
    background-image: $login-bg-tablet;
  }
  @media (max-width: 576px) {
    background-image: $login-bg-mobile;
  }
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 16px;
  svg {
    display: block;
    width: 432px;
    height: 164px;
    max-height: unset !important;
    margin-top: 84px;
    margin-bottom: 56px;
    path {
      fill: $errorpage-icon-color !important;
    }
  }
  h1 {
    font-size: 58px;
    font-weight: 600;
    color: $errorpage-title-color;
  }
  p {
    font-size: 16px;
    color: $errorpage-text-color;
    margin: 32px 0;
  }
  button {
    background: $errorpage-btn-bg-color;
    color: $errorpage-btn-text-color;
    font-size: 14px;
    padding: 8px 16px;
    border: 0;
    border-radius: $border-radius;
  }
  .errorImage{
    margin-top: 220px;
  }
}

@import './popleads_mobile.scss';
@import './popleads_rtl.scss';

.simpleModalBody {
  color: #464e5f;
  margin-top: 10px;
  font-size: 1.5rem;
}

.simpleModalTitle {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 2rem;
}
.isPasswordChangeModalBody {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.successOkBtn {
  background: #00c48c !important;
}

.coursePreview_noContent {
  color: $myCourse-subjects-color;
  margin: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.completedTag {
  font-size: 16px;
  font-weight: bold;
  color: $myCourse-completed-text-color;
  border: 2px solid $myCourse-completed-text-color;
  padding: 6px 16px;
}

// Switch for course content language change
.languageSwitch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 25px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid $myCourse-subjects-lang-toggle-background-color;
    border-radius: 34px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    content: attr(data-language);
    font-size: 12px;
    font-weight: bold;
    color: $myCourse-subjects-lang-toggle-text-color;
    height: 32px;
    width: 32px;
    left: -8px;
    top: -5px;
    border-radius: 50%;
    background-color: $myCourse-subjects-lang-toggle-background-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
  }

  //   input:checked + .slider {
  //     background-color: xxx;
  //   }
}

.careerPath {
  @extend .allCourses;
  .allCoursesGridItemTitle {
    margin-top: 0 !important;
  }
  .titlePrimary > h2 {
    color: $career-title-primary-color !important;
  }
  .titleSecondary > h2 {
    color: $career-title-secondary-color !important;
  }

  button.jobBtn {
    height: 36px;
    font-size: 14px;
    border: 0;
    padding: 8px 16px;
    background-color: $career-btn-primary-bg-color !important;
    color: $career-btn-primary-text-color !important;
    &:disabled {
      opacity: 0.6 !important;
    }
  }

  .bestMatching {
    @extend .fullWidthChild;
    background-color: $landing-background-color;

    .desktopPath {
      .verticalStep {
        width: 10px;
        height: 70px;
      }
      .matchRateProgress {
        position: absolute;
        height: 100%;
      }
      .actual {
        margin-top: 110px;
        .verticalStep {
          background: linear-gradient($career-level1Pos-color, $career-actualPos-color);
        }
        .matchRateProgress {
          background-color: $career-actualPos-color;
        }
      }
      .level1 {
        margin-top: 50px;
        .verticalStep {
          background: $career-posProgress-bg-color;
          &.showGradient {
            background: linear-gradient(
              $career-level2Pos-color,
              $career-level1Pos-color
            ) !important;
          }
        }
        .matchRateProgress {
          background-color: $career-level1Pos-color;
        }
      }
      .level2 {
        margin-top: 50px;
        .matchRateProgress {
          background-color: $career-level2Pos-color;
        }
      }

      .progressStick {
        height: 10px;
        background-color: $career-posProgress-bg-color;
        position: relative;
        .positionDetails {
          position: absolute;
          width: 100%;
          text-align: center;
          color: $career-posDetails-text-color;
          &.matchRate {
            bottom: 32px;
            font-size: 24px;
            font-weight: bold;
          }
          &.matchSubtitle {
            bottom: 16px;
            font-size: 12px;
          }
          &.jobTitle {
            top: 16px;
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
    }

    .mobilePath {
      .progressStick {
        width: 10px;
        height: 140px;
        background-color: $career-posProgress-bg-color;
        position: relative;
      }

      .positionDetails {
        display: flex;
        flex-direction: column;
        margin-left: 18px;
        .matchSubtitle {
          font-size: 12px;
        }
        .jobTitle {
          font-size: 16px;
          font-weight: bold;
          margin-top: 4px;
          margin-bottom: 12px;
        }
        .matchRate {
          font-size: 16px;
          font-weight: bold;
        }
      }

      .position-level-1 {
        margin-left: 40px;
      }
      .position-actual {
        margin-left: 60px;
      }

      .matchRateProgress {
        position: absolute;
        width: 100%;
      }

      .actual .matchRateProgress {
        background-color: $career-actualPos-color;
      }

      .horizontalStep1 {
        width: 30px;
        height: 10px;
        background: linear-gradient(90deg, $career-actualPos-color, $career-level1Pos-color);
      }

      .level1 {
        margin-left: 20px;
        .matchRateProgress {
          background-color: $career-level1Pos-color;
        }
      }

      .horizontalStep2 {
        width: 30px;
        height: 10px;
        margin-left: 20px;
        background: $career-posProgress-bg-color;
        &.showGradient {
          background: linear-gradient(90deg, $career-level1Pos-color, $career-level2Pos-color);
        }
      }

      .level2 {
        margin-left: 40px;
        .matchRateProgress {
          background-color: $career-level2Pos-color;
        }
      }
    }
  }

  .comparison {
    @extend .fullWidthChild;
    background-color: $secondary-background-color;
    .comparisonTitle {
      color: $career-title-tertiary-color !important;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .comparisonSubtitle {
      font-size: 12px;
      margin-bottom: 4px;
    }
    .mobilePercentage {
      font-size: 16px;
      font-weight: bold;
    }
  }

  span.delete-icon {
    svg {
      path {
        stroke: $all-courses-item-time-length-color !important;
      }
    }
  }

  .jobSelect {
    z-index: 10;
    // color: $career-selector-text-color !important;

    // .css-1s2u09g-control, .css-1insrsq-control {
    //     background-color: $career-selector-bg-color !important;
    //     border: 0 !important;
    // }
    // .css-1insrsq-control {
    //     opacity: 0.7 !important;
    // }
    // .css-qc6sy-singleValue, .css-14el2xx-placeholder {
    //     color: $career-selector-text-color !important;
    // }
    // .css-1okebmr-indicatorSeparator {
    //     display: none !important;
    // }
    // .css-tj5bde-Svg {
    //     fill: $career-selector-text-color !important;
    // }
  }

  .selectedRole {
    font-size: 12px;
    color: $career-selector-text-color;
  }

  button.addJob {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 4px;
    width: 38px !important;
    height: 38px !important;
    background-color: $career-btn-primary-bg-color !important;
    svg {
      path {
        stroke: $career-btn-primary-text-color !important;
        fill: $career-btn-primary-text-color !important;
      }
    }
    &:disabled {
      opacity: 0.6 !important;
    }
  }

  .competency-title {
    font-size: 18px;
    font-weight: bold;
    color: $career-title-secondary-color;
    margin-bottom: 18px;
  }

  .graphLabel {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: $career-title-primary-color;
    &::before {
      content: '';
      width: 14pt;
      height: 14pt;
      border-radius: 50%;
      margin-right: 12px;
    }
    &.actual::before {
      background-color: $career-graph-actual-bg-color;
      border: 1px solid $career-graph-actual-bg-color;
    }
    &.necessary::before {
      background-color: $career-graph-required-bg-color;
      border: 1px solid $career-graph-required-bg-color;
    }
  }

  .competencyGraph {
    .chart {
      margin-top: 24px;
      background-image: linear-gradient($primary-background-color 5px, transparent 0),
        linear-gradient(90deg, #cccccc 1px, transparent 0);
      background-size: 15px 15px, calc(25% - 6px) calc(25% - 6px);
      background-position: -1px 0;
      padding-right: 24px;
      border-style: solid;
      border-width: 1px;
      border-color: #3e3e3e;
      border-right: none;
      border-top: none;
    }
    .competency {
      height: 36px;
      margin-top: 8px;
      position: relative;
    }
    .requiredLevel,
    .actualLevel,
    .levelName {
      position: absolute;
      height: 100%;
    }
    .requiredLevel {
      background-color: $career-graph-required-bg-color;
    }
    .actualLevel {
      background-color: $career-graph-actual-bg-color;
    }
    .levelName {
      padding-left: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: $career-title-primary-color;
    }
    .levelLabels {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      letter-spacing: 0.2px;
      margin-top: 8px;
      color: $career-title-primary-color;
    }
  }

  .spinner-border {
    color: $career-title-secondary-color !important;
  }
}

.developmentPlan {
  .desktopTable {
    color: $devplan-primary-text-color !important;
    font-size: 16px !important;
    thead {
      font-weight: bold !important;
    }
    thead,
    tbody {
      border-width: 1px !important;
      border-color: $devplan-table-border-color !important;
    }
    th:first-child,
    td:first-child {
      padding-left: 0.75rem !important;
    }
    th:last-child,
    td:last-child {
      padding-right: 0.75rem !important;
    }
    .competencies {
      cursor: pointer;
    }
  }
  button.status {
    font-size: 12px;
    border: 1px solid $devplan-btn-primary-text-color;
    border-radius: 2px;
    padding: 4px 12px;
    background-color: transparent;
    color: $devplan-btn-primary-text-color;
    cursor: default;
  }
  .mobileTable {
    @extend .desktopTable;
    .mobileRow {
      display: flex;
      width: 100%;
      border: 1px solid $devplan-table-border-color !important;
      .title {
        flex: 4;
        padding: 10px 6px;
        color: $devplan-btn-primary-text-color;
        border-right: 1px solid $devplan-table-border-color !important;
      }
      .text {
        flex: 9;
        padding: 10px 6px;
      }
    }
  }
}

.heading-font-family {
  font-family: $heading-font-family !important;
}
